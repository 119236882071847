<template>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout transport-overview transport-overview-accepted headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/courier-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle">Aangenomen transporten</h1>
            </div>
          </div>
          <div class="row" v-if="loading" id="extra-margin">
            <div class="col-12 text-center">
              <SpinnerBigIcon/>
            </div>
          </div>

          <div class="row" v-if="!loading && !orders.length">
            <div class="col-12">
              <div class="empty">
                <div class="content-left">
                  <h5 class="">Geen transporten gevonden...</h5>
                </div>
                <div class="content-right">
                  <router-link to="/dashboard/courier-transports-open" class="btn btn-primary">Bekijk openstaande transporten</router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="!loading && orders.length">

            <!-- orders -->

            <div class="col-12 col-lg-6" v-for="(order, orderIndex) in orders" :key="order.id">
              <div class="transport-item">
                <h4 class="transport-title">Transport van {{ order.itemsCount }} {{ order.itemsCount === 1 ? 'item' : 'items' }}</h4>
                <div class="info-alert info-alert-smaller">
                  <div class="image">
                    <img src="~@/assets/img/icon-calendar.svg" alt="calendar">
                  </div>
                  <div class="text">
                    <p class="smaller">Gepland</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner first-item">
                      <img src="~@/assets/img/icon-euro.svg" width="28" height="29" alt="euro">
                      <p class="inner-title">Vergoeding</p>
                      <div class="details">
                        <p class="bold">&euro; {{ formatPrice(order.priceSubtotal * 0.8) }}</p>
                        <span>excl. btw</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner">
                      <img src="~@/assets/img/icon-marker-green.svg" width="28" height="29" alt="marker">
                      <p class="inner-title">Totale afstand</p>
                      <div class="details">
                        <p class="bold">{{ order.distance }}</p>
                        <span>km</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner last-item">
                      <img src="~@/assets/img/icon-volume.svg" width="28" height="29" alt="volume">
                      <p class="inner-title">Totaal volume</p>
                      <div class="details">
                        <p class="bold">{{ calculateVolumeItems(order.items) }}</p>
                        <span>m&#179;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="route-content">
                  <div class="route-content-inner">
                    <div class="route-info">
                      <div class="text">
                        <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                        <p class="bold">{{ order.pickupTime === 'custom' ? formatCustomTimePeriod(order.pickupTimeCustom, 'pickup') : formatDayPeriod(order.pickupTime) }}</p>
                      </div>
                      <div class="courier-container">
                        <div class="courier">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                        <div class="courier" v-if="order.help">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                      </div>
                    </div>
                    <div class="route-location">
                      <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                      <p class="smaller">{{ order.pickupAddress }}</p>
                    </div>
                  </div>
                  <div class="route-content-inner">
                    <div class="route-info">
                      <div class="text">
                        <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                        <p class="bold">{{ order.deliveryTime === 'custom' ? formatCustomTimePeriod(order.deliveryTimeCustom, 'delivery') : formatDayPeriod(order.deliveryTime) }}</p>
                      </div>
                      <div class="courier-container">
                        <div class="courier">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                        <div class="courier" v-if="order.help">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                      </div>
                    </div>
                    <div class="route-location">
                      <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                      <p class="smaller">{{ order.deliveryAddress }}</p>
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary btn-new btn-black mb-3 d-none d-lg-block" @click="downloadOrder(order.id)" :disabled="downloading">
                  <img class="mr-2" src="~@/assets/img/icon-download.svg" alt="download"> Download opdrachtbevestiging
                </button>
                <button class="btn btn-primary btn-new btn-black mb-3 d-lg-none" @click="downloadOrder(order.id)" :disabled="downloading">
                  <img class="mr-2" src="~@/assets/img/icon-download.svg" alt="download"> Download bevestiging
                </button>
                <button class="btn btn-primary btn-new" @click="showDetails(orderIndex)">Bekijk details</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- order details modal -->

    <div class="modal fade" id="transportDetailModal" tabindex="-1" role="dialog" aria-labelledby="transportDetailModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header modal-header-with-extra-info">
            <h3 class="modal-title" id="transportDetailModalTitle">Transport van {{ orderDetails.itemsCount }} {{ orderDetails.itemsCount === 1 ? 'item' : 'items' }}</h3>
            <div class="info-alert info-alert-smaller">
              <div class="image">
                <img src="~@/assets/img/icon-calendar.svg" alt="calendar">
              </div>
              <div class="text">
                <p class="smaller">Gepland</p>
              </div>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="inner transport-detail extra-margin">
              <p>
                <i>Transport # {{ orderDetails.id }} aangenomen op {{ formatDateTimeLocale(orderDetails.transport.created) }}</i>
              </p>
            </div>
          </div>
          <div class="modal-body">
            <div id="map" v-if="orderDetails.markers && orderDetails.markers.length">
              <GMapMap
                :center="getMarkersMedian(orderDetails.markers)"
                :zoom="orderDetails.distance > 120 ? 7 : orderDetails.distance > 80 ? 8 : orderDetails.distance > 5 ? 9 : 12"
                :options="gMapOptions"
                ref="gMap"
              >
                <GMapMarker
                  v-for="(marker, markerIndex) in orderDetails.markers"
                  :key="markerIndex"
                  :position="marker.position"
                  :icon="'/assets/img/map-marker.svg'"
                />
                <GMapPolyline
                  :path="getDirections(orderDetails.markers, directions)"
                />
              </GMapMap>
            </div>
            <div class="row">
              <div class="col-12 col-lg-4">
                <div class="transport-item-inner first-item">
                  <img src="~@/assets/img/icon-euro.svg" width="28" height="29" alt="euro">
                  <p class="inner-title">Vergoeding</p>
                  <div class="details">
                    <p class="bold">&euro; {{ formatPrice(orderDetails.priceSubtotal * 0.8) }}</p>
                    <span>excl. btw</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="transport-item-inner">
                  <img src="~@/assets/img/icon-marker-green.svg" width="28" height="29" alt="marker">
                  <p class="inner-title">Totale afstand</p>
                  <div class="details">
                    <p class="bold">{{ orderDetails.distance }}</p>
                    <span>km</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="transport-item-inner last-item">
                  <img src="~@/assets/img/icon-volume.svg" width="28" height="29" alt="volume">
                  <p class="inner-title">Totaal volume</p>
                  <div class="details">
                    <p class="bold">{{ calculateVolumeItems(orderDetails.items) || '&mdash;' }}</p>
                    <span>m&#179;</span>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="subtitle">Route</h4>
            <div class="row">
              <div class="col-12 col-lg-6 first-col">
                <div class="route-inner">
                  <p class="smaller">{{ formatDateFull(orderDetails.dateDb) }}</p>
                  <p class="bold">{{ orderDetails.pickupTime === 'custom' ? formatCustomTimePeriod(orderDetails.pickupTimeCustom, 'pickup') : formatDayPeriod(orderDetails.pickupTime) }}</p>
                  <div class="route-location">
                    <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                    <p class="smaller">{{ orderDetails.pickupAddress }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Type gebouw</p>
                    <p class="smaller">{{ orderDetails.pickupBuildingType ? showLabel(orderDetails.pickupBuildingType, buildingTypes) : 'Onbekend' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Verdieping</p>
                    <p class="smaller">{{ orderDetails.pickupFloor ? showLabel(orderDetails.pickupFloor, floorTypes) : 'Begane grond' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Lift aanwezig?</p>
                    <p class="smaller">{{ orderDetails.deliveryType !== 'custom' || orderDetails.pickupFloor === 'ground floor' ? 'Onbekend' : (orderDetails.pickupElevator ? 'Ja' : 'Nee') }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Aantal koeriers</p>
                    <div class="half-inner">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier" v-if="orderDetails.help">
                    </div>
                  </div>
                  <div class="half border-top">
                    <p class="smaller semibold">{{ orderDetails.customer.companyName || 'Particulier' }}</p>
                    <p class="smaller"></p>
                  </div>
                  <div class="half">
                    <p class="smaller">{{ orderDetails.customer.fullName }}</p>
                    <p class="smaller">{{ orderDetails.pickupPhone || orderDetails.customer.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 d-lg-none text-center">
                <svg class="d-lg-none arrow-special" width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.5 1.3469L1.74093 0L9.5 7.46L17.2591 0L18.5 1.3469L9.5 10L0.5 1.3469Z" fill="#282A2F"/>
                </svg>
                <svg class="d-none d-lg-block arrow-special" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.3469 18L-5.42428e-08 16.7591L7.46 9L-7.32562e-07 1.24093L1.3469 -5.88748e-08L10 9L1.3469 18Z" fill="#282A2F"/>
                </svg>
              </div>
              <div class="col-12 col-lg-6 last-col">
                <div class="route-inner">
                  <p class="smaller">{{ formatDateFull(orderDetails.dateDb) }}</p>
                  <p class="bold">{{ orderDetails.deliveryTime === 'custom' ? formatCustomTimePeriod(orderDetails.deliveryTimeCustom, 'delivery') : formatDayPeriod(orderDetails.deliveryTime) }}</p>
                  <div class="route-location">
                    <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                    <p class="smaller">{{ orderDetails.deliveryAddress }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Type gebouw</p>
                    <p class="smaller">{{ orderDetails.deliveryBuildingType ? showLabel(orderDetails.deliveryBuildingType, buildingTypes) : 'Onbekend' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Verdieping</p>
                    <p class="smaller">{{ orderDetails.deliveryFloor ? showLabel(orderDetails.deliveryFloor, floorTypes) : 'Begane grond' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Lift aanwezig?</p>
                    <p class="smaller">{{ orderDetails.deliveryType !== 'custom' || orderDetails.deliveryFloor === 'ground floor' ? 'Onbekend' : (orderDetails.deliveryElevator ? 'Ja' : 'Nee') }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Aantal koeriers</p>
                    <div class="half-inner">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier" v-if="orderDetails.help">
                    </div>
                  </div>
                  <div class="half border-top">
                    <p class="smaller semibold">{{ orderDetails.customer.companyName || 'Particulier' }}</p>
                    <p class="smaller"></p>
                  </div>
                  <div class="half">
                    <p class="smaller">{{ orderDetails.customer.fullName }}</p>
                    <p class="smaller">{{ orderDetails.deliveryPhone || orderDetails.customer.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="information" v-if="orderDetails.note">
              <p class="bold">
                <img src="~@/assets/img/icon-warning.svg" alt="warning">
                Opmerking van aanvrager
              </p>
              <p class="smaller">{{ orderDetails.note }}</p>
            </div>
            <template v-if="orderDetails.items.length">
              <h4 class="subtitle">Items</h4>
              <div class="table-outer table-outer-extra-margin swiper-no-swiping">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Aantal</th>
                      <th>Item</th>
                      <th>Lengte</th>
                      <th>Breedte</th>
                      <th>Hoogte</th>
                      <th>Gewicht</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in orderDetails.items" :key="item.id">
                      <td>{{ item.amount }}</td>
                      <td class="with-icon" :class="itemTypes.find(itemType => itemType.value === item.type)['class']" v-if="!item.typeOther">
                        <span>{{ itemTypes.find(itemType => itemType.value === item.type)['label'] }}</span>
                      </td>
                      <td class="with-icon different" v-if="item.typeOther">
                        <span>Anders: {{ item.typeOther }}</span>
                      </td>
                      <td>{{ item.length }} cm</td>
                      <td>{{ item.width }} cm</td>
                      <td>{{ item.height }} cm</td>
                      <td>{{ item.weightClass }} kg</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { showLabel, formatPrice, formatDateFull, formatDayPeriod, formatDateTimeLocale, formatCustomTimePeriod } from '@/utils/format';
import { calculateVolumeItems } from '@/utils/calculate';
import { getMarkers, getMarkersMedian, getDirections } from '@/utils/map';
import SpinnerBigIcon from '@/components/common/SpinnerBigIcon';
import '@/assets/js/itemTypes';
import itemTypes from '@/assets/js/itemTypes';
import { openModal } from '@/utils/bs-modal';

export default {
  components: {
    SpinnerBigIcon
  },
  props: ['id'],
  data () {
    return {
      itemTypes: itemTypes,
      loading: true,
      downloading: false,
      floorTypes: require('@/assets/js/floorTypes.json'),
      buildingTypes: require('@/assets/js/buildingTypes.json'),
      orders: [],
      orderDetails: {
        'items': [],
        'customer': {},
        'transport': {}
      },
      gMapOptions: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      },
      directions: {}
    }
  },
  methods: {
    showLabel,
    formatPrice,
    formatDateFull,
    formatDayPeriod,
    formatDateTimeLocale,
    formatCustomTimePeriod,
    calculateVolumeItems,
    getMarkers,
    getMarkersMedian,
    getDirections,
    showDetails(orderIndex) {
      openModal('transportDetailModal');
      this.showModal = true;

      this.orderDetails = JSON.parse(JSON.stringify(this.orders[orderIndex]));
      this.getMarkers(orderIndex, this.orderDetails, this.orders);
    },
    getOrders () {
      this.loading = true;

      fetch('/api/orders/accepted')
        .then(response => response.ok ? response.json() : [])
        .then(data => {
          this.orders = data;

          if (this.id) {
            const orderSelected = this.orders.find(o => o.id === parseInt(this.id));

            if (orderSelected) {
              this.orderDetails = orderSelected;
              this.getMarkers(this.orders.findIndex(o => o.id === parseInt(this.id)), orderSelected, this.orders);
              console.log(this.orderDetails);

              openModal('transportDetailModal');
            }
          }

          this.loading = false;
        });
    },
    downloadOrder(orderId) {
      this.downloading = true;

      fetch('/api/orders/' + orderId + '/download-order')
        .then(response => response.blob())
        .then(blob => {

          if (blob) {
            const
              elLink = document.createElement('a'),
              fileUrl = window.URL.createObjectURL(blob)

            document.body.appendChild(elLink);
            elLink.href = fileUrl;
            elLink.download = 'Direct Transport order ' + orderId + '.pdf';
            elLink.click();

            setTimeout(() => {
              window.URL.revokeObjectURL(fileUrl);
              document.body.removeChild(elLink);
            }, 0);
          }

          this.downloading = false;
        });
    }
  },
  created () {
    this.getOrders();
  }
}

</script>
