// vanilla js open & close for bootstrap 4 modals

export function openModal(modalId) {
  const
    elBackdrop = document.createElement('div'),
    elModal = document.getElementById(modalId);

  if (elModal !== null) {

    // create backdrop

    elBackdrop.setAttribute('id', modalId + 'Backdrop');
    elBackdrop.classList.add('modal-backdrop', 'fade', 'show');
    document.body.appendChild(elBackdrop);

    // show modal

    elModal.classList.add('d-block', 'show');
    document.body.classList.add('modal-open');

    // attach event handlers

    if (!elModal.classList.contains('dirty')) {
      elModal.addEventListener('click', event => { event.target.id === modalId ? closeModal(modalId) : false });

      elModal.querySelectorAll('[data-dismiss="modal"]').forEach(elClose => {
        elClose.addEventListener('click', () => { closeModal(modalId); })
      });

      elModal.classList.add('dirty');
    }
  }
}

export function closeModal(modalId) {
  const
    elBackdrop = document.getElementById(modalId + 'Backdrop'),
    elModal = document.getElementById(modalId);

  if (elBackdrop !== null
    && elModal !== null
  ) {

    // remove backdrop

    elBackdrop.parentNode.removeChild(elBackdrop);

    // hide modal

    elModal.classList.remove('d-block', 'show');
    document.body.classList.remove('modal-open');
  }
}
