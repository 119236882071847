// calculate volume

export function calculateVolumeItems(items, toLocale = true) {
  let volume = 0;

  if (items) {

    for (const item of items) {

      if (
        item.length > 0
        && item.width > 0
        && item.height > 0
      ) {
        volume += ((item.length / 100) * (item.width / 100) * (item.height / 100)) * item.amount;
      }
    }
  }

  return toLocale ? volume.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : volume;
}
