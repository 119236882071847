export default [
  {
    'value': 'two seat sofa',
    'label': '2-zits bank',
    'class': 'two-person-couch',
    'length': 180,
    'width': 90,
    'height': 80,
    'weightClass': '20-80'
  },
  {
    'value': 'three seat sofa',
    'label': '3-zits bank',
    'class': 'three-person-couch',
    'length': 225,
    'width': 90,
    'height': 80,
    'weightClass': '20-80'
  },
  {
    'value': 'sleeping couch',
    'label': 'Slaapbank 2-persoons',
    'class': 'sleeping-couch',
    'length': 200,
    'width': 100,
    'height': 90,
    'weightClass': '20-80'
  },
  {
    'value': 'armchair',
    'label': 'Fauteuil',
    'class': 'fauteuill',
    'length': 80,
    'width': 80,
    'height': 120,
    'weightClass': '20-80'
  },
  {
    'value': 'dining room chair',
    'label': 'Eetkamerstoel',
    'class': 'dining-chair',
    'length': 40,
    'width': 50,
    'height': 80,
    'weightClass': '0-20'
  },
  {
    'value': 'table four person',
    'label': 'Eettafel 4-persoons',
    'class': 'four-person-table',
    'length': 140,
    'width': 80,
    'height': 75,
    'weightClass': '20-80'
  },
  {
    'value': 'table six person',
    'label': 'Eettafel 6-persoons',
    'class': 'six-person-table',
    'length': 180,
    'width': 90,
    'height': 75,
    'weightClass': '20-80'
  },
  {
    'value': 'table eight person',
    'label': 'Eettafel 8-persoons',
    'class': 'eighth-person-table',
    'length': 230,
    'width': 100,
    'height': 75,
    'weightClass': '80-100'
  },
  {
    'value': 'table',
    'label': 'Salontafel',
    'class': 'table',
    'length': 90,
    'width': 55,
    'height': 45,
    'weightClass': '0-20'
  },
  {
    'value': 'desk',
    'label': 'Bureau',
    'class': 'desk',
    'length': 150,
    'width': 65,
    'height': 75,
    'weightClass': '20-80'
  },
  {
    'value': 'single bed',
    'label': '1-persoonsbed',
    'class': 'one-person-bed',
    'length': 80,
    'width': 190,
    'height': 50,
    'weightClass': '20-80'
  },
  {
    'value': 'double bed',
    'label': '2-persoonsbed',
    'class': 'double-bed',
    'length': 160,
    'width': 190,
    'height': 50,
    'weightClass': '20-80'
  },
  {
    'value': 'boxspring',
    'label': 'Boxspring',
    'class': 'boxspring',
    'length': 200,
    'width': 200,
    'height': 60,
    'weightClass': '80-100'
  },
  {
    'value': 'closet single door',
    'label': 'Kast enkeldeurs',
    'class': 'closet-single-door',
    'length': 80,
    'width': 60,
    'height': 180,
    'weightClass': '0-20'
  },
  {
    'value': 'closet double door',
    'label': 'Kast dubbeldeurs',
    'class': 'closet-double-door',
    'length': 170,
    'width': 60,
    'height': 200,
    'weightClass': '20-80'
  },
  {
    'value': 'dressoir',
    'label': 'Dressoir',
    'class': 'dressoir',
    'length': 200,
    'width': 40,
    'height': 80,
    'weightClass': '80-100'
  },
  {
    'value': 'refrigerator small',
    'label': 'Koelkast tafelmodel',
    'class': 'refrigerator-small',
    'length': 60,
    'width': 60,
    'height': 85,
    'weightClass': '20-80'
  },
  {
    'value': 'refrigerator',
    'label': 'Koelkast hoog',
    'class': 'refrigerator',
    'length': 60,
    'width': 60,
    'height': 190,
    'weightClass': '80-100'
  },
  {
    'value': 'freezer',
    'label': 'Vriezer tafelmodel',
    'class': 'freezer',
    'length': 60,
    'width': 60,
    'height': 85,
    'weightClass': '20-80'
  },
  {
    'value': 'washing machine',
    'label': 'Wasmachine',
    'class': 'washing-machine',
    'length': 60,
    'width': 60,
    'height': 85,
    'weightClass': '80-100'
  },
  {
    'value': 'dryer',
    'label': 'Droger',
    'class': 'dryer',
    'length': 60,
    'width': 60,
    'height': 85,
    'weightClass': '20-80'
  },
  {
    'value': 'bicycle',
    'label': 'Fiets',
    'class': 'bicycle',
    'length': 190,
    'width': 80,
    'height': 90,
    'weightClass': '0-20'
  },
  {
    'value': 'scooter',
    'label': 'Scooter',
    'class': 'scooter',
    'length': 190,
    'width': 80,
    'height': 100,
    'weightClass': '100+'
  },
  {
    'value': 'motor',
    'label': 'Bromfiets',
    'class': 'motor',
    'length': 180,
    'width': 70,
    'height': 90,
    'weightClass': '80-100'
  },
  {
    'value': 'scootmobile',
    'label': 'Scootmobiel',
    'class': 'scootmobile',
    'length': 120,
    'width': 60,
    'height': 100,
    'weightClass': '20-80'
  },
  {
    'value': 'cargo-bike',
    'label': 'Bakfiets',
    'class': 'cargo-bike',
    'length': 260,
    'width': 69,
    'height': 110,
    'weightClass': '20-80'
  },
  {
    'value': 'box',
    'label': 'Verhuisdoos',
    'class': 'box',
    'length': 50,
    'width': 32,
    'height': 33,
    'weightClass': '0-20'
  },
  {
    'value': 'other',
    'label': 'Anders:',
    'class': 'different',
    'length': 0,
    'width': 0,
    'height': 0,
    'weightClass': '0-20'
  }
];
