<template>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout settings headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/courier-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle">Instellingen</h1>
            </div>
            <div class="col-12">
              <div class="mobile d-lg-none">
                <div class="form-group">
                  <select class="form-control select-form-group">
                    <option>Bedrijfsgegevens</option>
                  </select>
                </div>
              </div>
              <div class="desktop d-none d-lg-block">
                <ul class="nav nav-tabs" id="tabContentPills" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="courier-tab" data-toggle="tab" href="#courier" role="tab" aria-controls="courier" aria-selected="true">Bedrijfsgegevens</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="tabContent">
                <div class="tab-pane fade show active" id="courier" role="tabpanel" aria-labelledby="courier-tab">
                  <p class="intro-paragraph">Bedrijfsgegevens kunnen hieronder worden aangepast.</p>
                  <form class="inner" ref="formCourierCompany" v-on:submit.prevent>
                    <h5 class="title">Bedrijfsgegevens</h5>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-company-name-field" class="label-required">Bedrijfsnaam</label>
                          <input id="courier-company-name-field" type="text" class="form-control" placeholder="Bedrijfsnaam..." v-model="courier.company.companyName" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-coc-field" class="label-required">KvK-nummer</label>
                          <input id="courier-coc-field" type="text" class="form-control" placeholder="KvK-nummer" v-model="courier.company.coc" required>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-postal-code-field" class="label-required">Postcode</label>
                          <input id="courier-postal-code-field" type="text" class="form-control" placeholder="Postcode..." v-model="courier.company.postalCode" @change="setAddress" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3">
                        <div class="form-group">
                          <label for="courier-house-number-field" class="label-required">Huisnr.</label>
                          <input id="courier-house-number-field" type="text" class="form-control" placeholder="Huisnr..." v-model="courier.company.houseNumber" @change="setAddress" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3">
                        <div class="form-group">
                          <label for="courier-house-number-addition-field">Toev.</label>
                          <input id="courier-house-number-addition-field" type="text" class="form-control" placeholder="Toev..." v-model="courier.company.houseNumberAddition" @change="setAddress">
                        </div>
                      </div>
                    </div>
                    <div class="result" v-show="courier.company.address">
                      <img src="~@/assets/img/icon-marker.svg" alt="marker">
                      <span>{{ courier.company.address }}</span>
                    </div>
                    <button type="submit" class="btn-new btn-primary float-right" @click="updateCourier('company')">Wijzigingen opslaan</button>
                  </form>
                  <form class="inner" ref="formCourierContact" v-on:submit.prevent>
                    <h5 class="title">Contactpersoon</h5>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-first-name-field">Voornaam</label>
                          <input id="courier-first-name-field" type="text" class="form-control" placeholder="Voornaam..." v-model="courier.contact.firstName">
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-last-name-field">Achternaam</label>
                          <input id="courier-last-name-field" type="text" class="form-control" placeholder="Achternaam..." v-model="courier.contact.lastName">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-email-field">E-mailadres</label>
                          <input id="courier-email-field" type="email" class="form-control" placeholder="E-mailadres..." v-model="courier.contact.email" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-phone-field">Telefoonnummer</label>
                          <input id="courier-phone-field" type="text" class="form-control" placeholder="Telefoonnummer..." v-model="courier.contact.phone">
                        </div>
                      </div>
                      <div class="info-alert info-alert-header" v-if="emailExists">
                        <div class="image">
                          <img src="~@/assets/img/icon-info.svg" alt="info">
                        </div>
                        <div class="text">
                          <p>Er is al een account met dit e-mailadres. Kies een ander e-mailadres.</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="user-password-field">Nieuw wachtwoord</label>
                          <input type="password" id="user-password-field" class="form-control" placeholder="Wachtwoord..." autocomplete="off" v-model="courier.contact.password">
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="user-password-check-field">Herhaal wachtwoord</label>
                          <input type="password" id="user-password-check-field" class="form-control" placeholder="Herhaal wachtwoord..." autocomplete="off" v-model="courier.contact.passwordCheck">
                        </div>
                      </div>
                    </div>
                    <button class="btn-new btn-primary float-right" @click="updateCourier('contact')">Wijzigingen opslaan</button>
                  </form>
                  <form class="inner" ref="formCourierVehicle" v-on:submit.prevent>
                    <h5 class="title">Voertuig en frequent gereden routes</h5>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-number-plate-field" class="label-required">Kenteken</label>
                          <input id="courier-number-plate-field" type="text" minlength="6" maxlength="8" class="form-control" placeholder="Kenteken..." v-model="courier.vehicle.numberPlate" @change="setCar" required>
                        </div>
                        <div class="result result-vehicle" v-if="courier.vehicle.car">
                          <span>{{ courier.vehicle.car }}</span>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="courier-tailgate-field" class="label-required">Heb je een laadklep?</label>
                          <select id="courier-tailgate-field" class="form-control" v-model="courier.vehicle.tailgate" required>
                            <option :value="true">Ja</option>
                            <option :value="false">Nee</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="label-required">Vrije laadruimte voertuig</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-4">
                        <div class="form-group">
                          <input type="number" class="form-control" placeholder="Lengte in cm" v-model="courier.vehicle.cargoSpaceLength" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="form-group">
                          <input type="number" class="form-control" placeholder="Breedte in cm" v-model="courier.vehicle.cargoSpaceWidth" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="form-group">
                          <input type="number" class="form-control" placeholder="Hoogte in cm" v-model="courier.vehicle.cargoSpaceHeight" required>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="courier-routes-field">Wat zijn frequent door jou gereden routes?</label>
                          <input id="courier-routes-field" type="text" class="form-control" placeholder="Gereden routes..." v-model="courier.vehicle.routes">
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn-new btn-primary float-right" @click="updateCourier('vehicle')">Wijzigingen opslaan</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>

  <!-- alert -->

  <div class="alerts alerts-success" role="alert" v-if="showPasswordAlert">
    <div class="image">
      <img src="~@/assets/img/check-white-round.svg" width="27" height="27" alt="check">
    </div>
    <div class="text">
      <p class="bold extra-margin">Wachtwoord succesvol opgeslagen</p>
      <p class="smaller">Je nieuwe wachtwoord is succesvol opgeslagen.</p>
    </div>
  </div>
</template>

<script>

import validateForm from '@/utils/form-validate';

export default {
  props: ['user'],
  data () {
    return {
      showPasswordAlert: false,
      courier: {
        'user': {
          'id': ''
        },
        'company': {
          'companyName': '',
          'coc': '',
          'postalCode': '',
          'houseNumber': '',
          'houseNumberAddition': '',
          'street': '',
          'city': '',
          'address': ''
        },
        'contact': {
          'firstName': '',
          'lastName': '',
          'email': '',
          'phone': '',
          'password': '',
          'passwordCheck': ''
        },
        'vehicle': {
          'numberPlate': '',
          'car': '',
          'tailgate': false,
          'cargoSpaceLength': '',
          'cargoSpaceWidth': '',
          'cargoSpaceHeight': '',
          'routes': ''
        }
      },
      validAddress: true,
      emailExists: false,
      emailInit: ''
    }
  },
  methods: {
    setAddress () {
      let validAddress = false;

      if (this.courier.company.postalCode
        && this.courier.company.houseNumber
      ) {
        this.courier.company.postalCode = this.courier.company.postalCode.toUpperCase().replace(/\s/g, '');

        fetch('/api/orders/validate-postal-code/' + this.courier.company.postalCode + '/' + this.courier.company.houseNumber)
          .then(response => response.ok ? response.json() : [])
          .then(data => {

            if (data['valid']) {
              validAddress = true;
              this.validAddress = true;

              this.courier.company.street = data['street'];
              this.courier.company.city = data['city'];
              this.courier.company.address =
                this.courier.company.street
                + ' '
                + this.courier.company.houseNumber
                + this.courier.company.houseNumberAddition
                + ' in ' + this.courier.company.city;
            }
          })
      }

      if (!validAddress) {
        this.courier.company.street = '';
        this.courier.company.city = '';
        this.courier.company.address = '';
        this.validAddress = false;
      }
    },
    setCar () {
      this.courier.vehicle.numberPlate = this.courier.vehicle.numberPlate.toUpperCase().replaceAll('-', '');
      this.courier.vehicle.car = '';

      if (/^[A-Z0-9]{6}$/.test(this.courier.vehicle.numberPlate)) {

        fetch('/api/couriers/vehicles/search/' + this.courier.vehicle.numberPlate)
          .then(response => response.ok ? response.text() : '')
          .then(data => {
            this.courier.vehicle.car = data;
          })
      }
    },
    getCourier () {

      fetch('/api/couriers/' + this.user['courierId'])
        .then(response => response.ok ? response.json() : this.courier)
        .then(data => {
          this.courier = data;
          this.emailInit = JSON.parse(JSON.stringify(this.courier.contact.email));
        })
    },
    updateCourier(type) {
      this.saving = true;

      let courierUpdate = { 'courier': this.courier[type] };

      if (type === 'contact') {
        courierUpdate['user'] = this.courier.user.id;
      }

      fetch('/api/couriers/' + this.user['courierId'], {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(courierUpdate)
      })
        .then(response => {
          this.saving = false;

          if (response.ok && type === 'contact') {

            if (this.courier.contact.password) {
              this.showPasswordAlert = true;
              this.courier.contact.password = '';
              this.courier.contact.passwordCheck = '';

              setTimeout(() => {
                this.showPasswordAlert = false;
              }, 3000);
            }
          }
        })
    }
  },
  watch: {
    'courier.company': {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCourierCompany'], this.validAddress);
      }
    },
    'courier.contact': {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCourierContact'], !this.courier.contact.password || this.courier.contact.password === this.courier.contact.passwordCheck);
      }
    },
    'courier.contact.email': {
      handler: function () {
        this.emailExists = false;

        if (this.courier.contact.email
          && this.courier.contact.email !== this.emailInit
          && document.querySelector('#courier-email-field:invalid') === null
        ) {

          fetch('/api/users/check/' + encodeURIComponent(this.courier.contact.email))
            .then(response => response.ok ? response.json() : false)
            .then(data => {
              this.emailExists = data;
            })
        }
      }
    },
    'courier.vehicle': {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCourierVehicle']);
      }
    }
  },
  created () {
    this.getCourier();
  }
}

</script>
