<template>
  <div class="container-outer overwrite">
    <main id="full" class="white-bg">
      <section class="dashboard headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="maintitle">{{ salute }} {{ user.name }},</h1>
              <div class="link-container">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <router-link to="/dashboard/courier-transports-open">
                      <svg width="70" height="50" viewBox="0 0 70 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25" cy="25" r="23.5" stroke="#25A268" stroke-width="3"/>
                        <path d="M25 12V25M25 38V25M25 25L12 24.9939M25 25L38 24.9939" stroke="#25A268" stroke-width="3"/>
                      </svg>
                      <div class="number" v-if="orderCounts.open">{{ orderCounts.open }}</div>
                      <h4 class="title">Openstaande transporten</h4>
                    </router-link>
                  </div>
                  <div class="col-12 col-lg-4">
                    <router-link to="/dashboard/courier-transports-accepted">
                      <svg width="71" height="50" viewBox="0 0 71 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1201_29664)">
                        <rect x="2.16797" y="6.5" width="47" height="42" stroke="#25A268" stroke-width="3"/>
                        <path d="M15.668 0L15.668 13" stroke="#25A268" stroke-width="3" stroke-linejoin="round"/>
                        <path d="M35.668 0L35.668 13" stroke="#25A268" stroke-width="3" stroke-linejoin="round"/>
                        <path d="M0.667969 22H50.668" stroke="#25A268" stroke-width="3" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1201_29664">
                        <rect width="70" height="50" fill="white" transform="translate(0.667969)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <div class="number" v-if="orderCounts.accepted">{{ orderCounts.accepted }}</div>
                      <h4 class="title">Aangenomen transporten</h4>
                    </router-link>
                  </div>
                  <div class="col-12 col-lg-4">
                    <router-link to="/dashboard/courier-transports-delivered">
                      <svg width="71" height="50" viewBox="0 0 71 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1201_29633)">
                        <circle cx="25.332" cy="25" r="23.5" stroke="#25A268" stroke-width="3"/>
                        <path d="M12.332 24.5015L21.8328 34.0015L38.8328 17" stroke="#25A268" stroke-width="3"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1201_29633">
                        <rect width="70" height="50" fill="white" transform="translate(0.332031)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <div class="number" v-if="orderCounts.delivered">{{ orderCounts.delivered }}</div>
                      <h4 class="title">Afgeleverde transporten</h4>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>

export default {
  props: ['user'],
  data () {
    return {
      orderCounts: {
        'open': 0,
        'accepted': 0,
        'delivered': 0
      }
    }
  },
  methods: {
    getOrderCounts () {

      fetch('/api/orders/counts')
        .then(response => response.ok ? response.json() : this.orderCounts)
        .then(data => {
          this.orderCounts = data;
        })
    }
  },
  computed: {
    salute () {
      const curDate = new Date();

      if (curDate.getHours() >= 18) {
        return 'Goedeavond';
      } else if (curDate.getHours() >= 12) {
        return 'Goedemiddag';
      } else {
        return 'Goedemorgen';
      }
    }
  },
  created () {
    this.getOrderCounts();
  }
};

</script>
