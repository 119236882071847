<template>
  <div class="confetti" :class="{ 'hide-confetti': !showConfetti }" style="z-index: 1100;"></div>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout transport-overview headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/courier-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle">Openstaande transporten</h1>
            </div>
            <div class="col-12" v-if="showInfo">
              <div class="info-alert">
                <div class="image">
                  <img src="~@/assets/img/icon-info.svg" alt="info">
                </div>
                <div class="text">
                  <p>Na het accepteren van een transport krijg je inzage in de contactgegevens en het exacte ophaal- en afleveradres.</p>
                </div>
                <div class="close" @click="showInfo = false">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L6 6M1 11L6 6M6 6L1 1L11 11" stroke="#25A268" stroke-width="1.5"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="loading" id="extra-margin">
            <div class="col-12 text-center">
              <SpinnerBigIcon/>
            </div>
          </div>

          <div class="row" v-if="!loading && !orders.length">
            <div class="col-12">
              <div class="empty">
                <div class="content-left">
                  <h5 class="">Geen transporten gevonden...</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="!loading && orders.length">

            <!-- orders -->

            <div class="col-12 col-lg-6" v-for="(order, orderIndex) in orders" :key="order.id">
              <div class="transport-item">
                <h4 class="transport-title">Transport van {{ order.itemsCount }} {{ order.itemsCount === 1 ? 'item' : 'items' }}</h4>
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner first-item">
                      <img src="~@/assets/img/icon-euro.svg" alt="euro">
                      <p class="inner-title">Vergoeding</p>
                      <div class="details">
                        <p class="bold">&euro; {{ formatPrice(order.priceSubtotal * 0.8) }}</p>
                        <span>excl. btw</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner">
                      <img src="~@/assets/img/icon-marker-green.svg" alt="marker">
                      <p class="inner-title">Totale afstand</p>
                      <div class="details">
                        <p class="bold">{{ order.distance }}</p>
                        <span>km</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner last-item">
                      <img src="~@/assets/img/icon-volume.svg" alt="volume">
                      <p class="inner-title">Totaal volume</p>
                      <div class="details">
                        <p class="bold">{{ calculateVolumeItems(order.items) }}</p>
                        <span>m&#179;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="route-content">
                  <div class="route-content-inner">
                    <div class="route-info">
                      <div class="text">
                        <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                        <p class="bold">{{ order.pickupTime === 'custom' ? formatCustomTimePeriod(order.pickupTimeCustom, 'pickup') : formatDayPeriod(order.pickupTime) }}</p>
                      </div>
                      <div class="courier-container">
                        <div class="courier">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                        <div class="courier" v-if="order.help">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                      </div>
                    </div>
                    <div class="route-location">
                      <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                      <p class="smaller">{{ order.pickupCity }}</p>
                    </div>
                  </div>
                  <div class="route-content-inner">
                    <div class="route-info">
                      <div class="text">
                        <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                        <p class="bold">{{ order.deliveryTime === 'custom' ? formatCustomTimePeriod(order.deliveryTimeCustom, 'delivery') : formatDayPeriod(order.deliveryTime) }}</p>
                      </div>
                      <div class="courier-container">
                        <div class="courier">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                        <div class="courier" v-if="order.help">
                          <img src="~@/assets/img/icon-courier.svg" width="16" height="17" alt="courier">
                        </div>
                      </div>
                    </div>
                    <div class="route-location">
                      <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                      <p class="smaller">{{ order.deliveryCity }}</p>
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary btn-new" @click="showDetails(orderIndex)">Bekijk details</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- orders modal -->

    <div class="modal fade" id="transportDetailModal" tabindex="-1" role="dialog" aria-labelledby="transportDetailModalLabel" aria-modal="true">
      <div class="modal-dialog" role="document" v-if="showModal">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="transportDetailModalLabel">Transport van {{ currentOrder.itemsCount }} {{ currentOrder.itemsCount === 1 ? 'item' : 'items' }}</h3>
            <button type="button" class="close" @click="closeModal('transportDetailModal')">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="inner transport-detail extra-margin">
              <p>
                <i>Transport # {{ currentOrder.id }}</i>
              </p>
            </div>
          </div>
          <div class="modal-body">
            <div id="map" class="swiper-no-swiping" v-if="currentOrder.markers && currentOrder.markers.length">
              <GMapMap
                :center="getMarkersMedian(currentOrder.markers)"
                :zoom="currentOrder.distance > 120 ? 7 : currentOrder.distance > 80 ? 8 : currentOrder.distance > 5 ? 9 : 12"
                :options="gMapOptions"
                ref="gMap"
              >
                <GMapMarker
                  v-for="(marker, markerIndex) in currentOrder.markers"
                  :key="markerIndex"
                  :position="marker.position"
                  :icon="'/assets/img/map-marker.svg'"
                />
                <GMapPolyline
                  :path="getDirections(currentOrder.markers, directions)"
                />
              </GMapMap>
            </div>
            <swiper
              @swiper="setControlledSwiper"
              :slides-per-view="1"
              :space-between="50"
              :auto-height="true"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="order in orders" :key="order.id">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner first-item">
                      <img src="~@/assets/img/icon-euro.svg" width="28" height="29" alt="euro">
                      <p class="inner-title">Vergoeding</p>
                      <div class="details">
                        <p class="bold">&euro; {{ formatPrice(order.priceSubtotal * 0.8) }}</p>
                        <span>excl. btw</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner">
                      <img src="~@/assets/img/icon-marker-green.svg" width="28" height="29" alt="marker">
                      <p class="inner-title">Totale afstand</p>
                      <div class="details">
                        <p class="bold">{{ order.distance }}</p>
                        <span>km</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="transport-item-inner last-item">
                      <img src="~@/assets/img/icon-volume.svg" width="28" height="29" alt="volume">
                      <p class="inner-title">Totaal volume</p>
                      <div class="details">
                        <p class="bold">{{ calculateVolumeItems(order.items) }}</p>
                        <span>m&#179;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="subtitle">Route</h4>
                <div class="row">
                  <div class="col-12 col-lg-6 first-col">
                    <div class="route-inner">
                      <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                      <p class="bold">{{ order.pickupTime === 'custom' ? formatCustomTimePeriod(order.pickupTimeCustom, 'pickup') : formatDayPeriod(order.pickupTime) }}</p>
                      <div class="route-location">
                        <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                        <p class="smaller">{{ order.pickupCity }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Type gebouw</p>
                        <p class="smaller">{{ order.pickupBuildingType ? showLabel(order.pickupBuildingType, buildingTypes) : 'Onbekend' }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Verdieping</p>
                        <p class="smaller">{{ order.pickupFloor ? showLabel(order.pickupFloor, floorTypes) : 'Begane grond' }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Lift aanwezig?</p>
                        <p class="smaller">{{ order.deliveryType !== 'custom' || order.pickupFloor === 'ground floor' ? 'Onbekend' : (order.pickupElevator ? 'Ja' : 'Nee') }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Aantal koeriers</p>
                        <div class="half-inner">
                          <img src="~@/assets/img/icon-courier.svg" alt="courier">
                          <img src="~@/assets/img/icon-courier.svg" alt="courier" v-if="order.help">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 d-lg-none text-center">
                    <svg class="d-lg-none arrow-special" width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 1.3469L1.74093 0L9.5 7.46L17.2591 0L18.5 1.3469L9.5 10L0.5 1.3469Z" fill="#282A2F"/>
                    </svg>
                    <svg class="d-none d-lg-block arrow-special" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.3469 18L-5.42428e-08 16.7591L7.46 9L-7.32562e-07 1.24093L1.3469 -5.88748e-08L10 9L1.3469 18Z" fill="#282A2F"/>
                    </svg>
                  </div>
                  <div class="col-12 col-lg-6 last-col">
                    <div class="route-inner">
                      <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                      <p class="bold">{{ order.deliveryTime === 'custom' ? formatCustomTimePeriod(order.deliveryTimeCustom, 'delivery') : formatDayPeriod(order.deliveryTime) }}</p>
                      <div class="route-location">
                        <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                        <p class="smaller">{{ order.deliveryCity }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Type gebouw</p>
                        <p class="smaller">{{ order.deliveryBuildingType ? showLabel(order.deliveryBuildingType, buildingTypes) : 'Onbekend' }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Verdieping</p>
                        <p class="smaller">{{ order.deliveryFloor ? showLabel(order.deliveryFloor, floorTypes) : 'Begane grond' }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Lift aanwezig?</p>
                        <p class="smaller">{{ order.deliveryType !== 'custom' || order.deliveryFloor === 'ground floor' ? 'Onbekend' : (order.deliveryElevator ? 'Ja' : 'Nee') }}</p>
                      </div>
                      <div class="half">
                        <p class="smaller semibold">Aantal koeriers</p>
                        <div class="half-inner">
                          <img src="~@/assets/img/icon-courier.svg" alt="courier">
                          <img src="~@/assets/img/icon-courier.svg" alt="courier" v-if="order.help">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="information" v-if="order.note">
                  <p class="bold">
                    <img src="~@/assets/img/icon-warning.svg" alt="warning">
                    Opmerking van aanvrager
                  </p>
                  <p class="smaller">{{ order.note }}</p>
                </div>
                <template v-if="order.items.length">
                  <h4 class="subtitle">Items</h4>
                  <div class="table-outer table-outer-extra-margin swiper-no-swiping">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Aantal</th>
                            <th>Item</th>
                            <th>Lengte</th>
                            <th>Breedte</th>
                            <th>Hoogte</th>
                            <th>Gewicht</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in order.items" :key="item.id">
                            <td>{{ item.amount }}</td>
                            <td class="with-icon" :class="itemTypes.find(itemType => itemType.value === item.type)['class']" v-if="!item.typeOther">
                              <span>{{ itemTypes.find(itemType => itemType.value === item.type)['label'] }}</span>
                            </td>
                            <td class="with-icon different" v-if="item.typeOther">
                              <span>Anders: {{ item.typeOther }}</span>
                            </td>
                            <td>{{ item.length }} cm</td>
                            <td>{{ item.width }} cm</td>
                            <td>{{ item.height }} cm</td>
                            <td>{{ item.weightClass }} kg</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </swiper-slide>
            </swiper>
          </div>
          <div id="buttons-footer" class="modal-footer modal-footer-extra-btn">
            <button type="button" class="btn btn-primary btn-new" :class="{ 'btn-transport-shift': shiftTransportButton }" @click="addTransport" :disabled="saving">Transport aannemen voor &euro; {{ formatPrice(currentOrder.priceSubtotal * 0.8) }}</button>
            <div class="button-container">
              <button class="btn-arrow btn-arrow-left" @click="swiper.slidePrev(); currentOrderIndex--" v-if="currentOrderIndex > 0">Vorige</button>
              <button class="btn-arrow btn-arrow-right" @click="swiper.slideNext(); currentOrderIndex++" v-if="orders.length > (currentOrderIndex + 1)">Volgende</button>
            </div>
          </div>
        </div>
      </div>
      <button class="btn-arrow btn-arrow-left d-none d-lg-block" @click="swiper.slidePrev(); currentOrderIndex--" v-if="currentOrderIndex > 0"></button>
      <button class="btn-arrow btn-arrow-right d-none d-lg-block" @click="swiper.slideNext(); currentOrderIndex++" v-if="orders.length > (currentOrderIndex + 1)"></button>
    </div>
  </div>

  <!-- transport accepted alert -->

  <div class="alerts alerts-success" role="alert" v-if="showAlert">
    <div class="image">
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
        <path d="M6.75 12.5945L11.875 17.5512L20.25 9.45117" stroke="#25A268" stroke-width="2.5"/>
      </svg>
    </div>
    <div class="text">
      <p class="bold">Transport succesvol aangenomen</p>
    </div>
  </div>

  <!-- transport error -->

  <div class="alerts alerts-warning" role="alert" v-if="showError">
    <div class="image">
      <svg width="27" height="27" version="1.1" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="#fff"/>
        <g fill="#f28f3b">
          <path d="m12.968 16.453c0.1576-0.1053 0.34288-0.1615 0.53242-0.1615 0.25412 0 0.49792 0.101 0.67762 0.2807s0.2807 0.4235 0.2807 0.6777c0 0.1895-0.0562 0.3748-0.1615 0.5324s-0.255 0.2804-0.4301 0.353c-0.1751 0.0725-0.36778 0.0915-0.55368 0.0545s-0.35666-0.1283-0.49068-0.2623c-0.13403-0.134-0.2253-0.3048-0.26228-0.4907s-0.018-0.3785 0.05454-0.5537c0.07253-0.1751 0.19536-0.3247 0.35296-0.4301z"/>
          <path d="m14.333 8.7913h-1.6666v5.8334h1.6666z"/>
          <path d="m3.5 13.5c0-5.5228 4.4772-10 10-10 5.5228 0 10 4.4772 10 10 0 5.5228-4.4772 10-10 10-5.5228 0-10-4.4772-10-10zm10-8.6167c-4.7589 0-8.6167 3.8578-8.6167 8.6167 0 4.7589 3.8578 8.6167 8.6167 8.6167 4.7589 0 8.6167-3.8578 8.6167-8.6167 0-4.7589-3.8578-8.6167-8.6167-8.6167z" clip-rule="evenodd" fill-rule="evenodd"/>
        </g>
      </svg>
    </div>
    <div class="text">
      <p class="bold">Transport niet meer beschikbaar</p>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import { openModal, closeModal } from '@/utils/bs-modal';
import { showLabel, formatPrice, formatDateFull, formatDayPeriod, formatCustomTimePeriod } from '@/utils/format';
import { calculateVolumeItems } from '@/utils/calculate';
import { getMarkers, getMarkersMedian, getDirections } from '@/utils/map';
import { Confettiful } from '@/utils/confetti';
import SpinnerBigIcon from '@/components/common/SpinnerBigIcon';
import '@/assets/js/itemTypes';
import itemTypes from '@/assets/js/itemTypes';

export default {
  components: {
    Swiper,
    SwiperSlide,
    SpinnerBigIcon
  },
  props: ['user'],
  data () {
    return {
      saving: false,
      loading: true,
      itemTypes: itemTypes,
      floorTypes: require('@/assets/js/floorTypes.json'),
      buildingTypes: require('@/assets/js/buildingTypes.json'),
      showConfetti: false,
      swiper: null,
      showModal: false,
      showAlert: false,
      showError: false,
      showInfo: true,
      shiftTransportButton: false,
      orders: [],
      currentOrder: {},
      currentOrderIndex: 0,
      directions: {},
      gMapOptions: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      }
    }
  },
  methods: {
    showLabel,
    closeModal,
    formatPrice,
    formatDateFull,
    formatDayPeriod,
    formatCustomTimePeriod,
    calculateVolumeItems,
    getMarkers,
    getMarkersMedian,
    getDirections,
    handleScroll(entries) {
      this.shiftTransportButton = entries[0].isIntersecting;
    },
    setControlledSwiper(swiper) {
      this.swiper = swiper;
    },
    showDetails(orderIndex) {
      openModal('transportDetailModal');
      this.showModal = true;

      this.currentOrder = JSON.parse(JSON.stringify(this.orders[orderIndex]));
      this.currentOrderIndex = orderIndex;
      this.getMarkers(orderIndex, this.currentOrder, this.orders);

      this.$nextTick(() => {
        const observer = new IntersectionObserver(this.handleScroll, {
          root: document.querySelector('#transportDetailModal'),
          rootMargin: '0px',
          threshold: 1.0
        });
        observer.observe(document.querySelector('#buttons-footer'));
        this.swiper.update();
        this.swiper.slideTo(orderIndex);
      });
    },
    onSlideChange () {
      this.currentOrder = JSON.parse(JSON.stringify(this.orders[this.swiper.activeIndex]));
      this.getMarkers(this.swiper.activeIndex, this.currentOrder, this.orders);
    },
    getOrders () {
      this.loading = true;

      fetch('/api/orders/open')
        .then(response => response.ok ? response.json() : [])
        .then(data => {
          this.orders = data;
          this.loading = false;
        })
    },
    addTransport () {
      this.saving = true;

      fetch('/api/transports', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'orderId': this.currentOrder.id })
      })
        .then(response => {
          this.saving = false;
          this.closeModal('transportDetailModal');

          if (response.ok) {
            this.showAlert = true;
            this.showConfetti = true;
            this.getOrders();

            setTimeout(() => {
              this.showAlert = false;
              this.showConfetti = false;
            }, 3000);
          } else {
            this.showError = true;
            this.getOrders();

            setTimeout(() => {
              this.showError = false;
            }, 3000);
          }
        })
    }
  },
  created () {
    this.getOrders();
  },
  mounted () {
    window.confettiful = new Confettiful(document.querySelector('.confetti'));
  }
}

</script>
