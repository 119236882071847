// set markers

export function getMarkers(orderIndex, order, orders) {
  order.markers = [];

  if (orders[orderIndex].markers) {
    order.markers = orders[orderIndex].markers;
  } else {
    let markersGet = [];

    // setup what to get

    if (order.pickupPlaceId) {
      markersGet.push({
        placeId: order.pickupPlaceId
      });
    } else {
      markersGet.push({
        address: order.pickupAddress
      });
    }

    if (order.deliveryPlaceId) {
      markersGet.push({
        placeId: order.deliveryPlaceId
      });
    } else {
      markersGet.push({
        address: order.deliveryAddress
      });
    }

    // get locations lat/lng for markers

    orders[orderIndex].markers = [];

    for (const markerGet of markersGet) {

      if (markerGet.address) {

        fetch('/api/orders/location-coordinates?address=' + encodeURIComponent(markerGet.address))
          .then(response => response.ok ? response.json() : {})
          .then(data => {

            if (data) {
              orders[orderIndex].markers.push({ position: data });
              order.markers.push({ position: data });
            }
          });
      } else {

        fetch('/api/orders/location-coordinates?place_id=' + encodeURIComponent(markerGet.placeId))
          .then(response => response.ok ? response.json() : {})
          .then(data => {

            if (data) {
              orders[orderIndex].markers.push({ position: data });
              order.markers.push({ position: data });
            }
          });
      }
    }
  }
}


// get markers median (for centering the map)

export function getMarkersMedian(markers) {

  if (markers.length === 2) {
    return {
      lat: (markers[0].position.lat + markers[1].position.lat) / 2,
      lng: (markers[0].position.lng + markers[1].position.lng) / 2
    }
  }

  return markers[0].position;
}


// get directions (for map polyline)

let loadingDirections = false;

export function getDirections(markers, directions) {

  if (markers.length === 2) {
    const directionsQuery = markers[0].position.lat + ',' + markers[0].position.lng + '/' + markers[1].position.lat + ',' + markers[1].position.lng;

    if (directions[directionsQuery]) {
      return directions[directionsQuery];
    }

    if (!loadingDirections) {
      loadingDirections = true;

      fetch('/api/orders/directions/' + directionsQuery)
        .then(response => response.ok ? response.json() : {})
        .then(data => {

          if (data) {
            directions[directionsQuery] = data;
            loadingDirections = false;
            return data;
          }
        })
    }
  }

  return [];
}
